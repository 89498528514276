.notificition-card {
  background:'#fff';
  -webkit-box-shadow:  0px 12px 26px rgba(16, 30, 115, 0.06);
  -moz-box-shadow:  0px 12px 26px rgba(16, 30, 115, 0.06);
  box-shadow:  0px 12px 26px rgba(16, 30, 115, 0.06);
  display: flex;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
 
  .profile_image{
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 40px;
  }
  .rowBetween{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .font-16{
    font-size: 16px;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}
